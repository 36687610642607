* {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
}

body {
    font-family: Arial, Helvetica, sans-serif;
    background: #f7f7f7c4 !important;
    color: #5a5a5a !important;
}

.infor-color {
    background-color: #006380 !important;
}

.cancel-color {
    background-color: #c51818 !important;
}

.content-body {
    background: #fefefe;
    margin: 30px auto;
    padding-top: 25px;
    padding-bottom: 25px;
    border-radius: 5px;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,.25);
}

.form-padrao {
    max-width: 100%;
    padding-top: 23px;
    border-radius: 5px;
    display: flex;
    justify-content: right;
    flex-direction: column;
}

.form-check-box {
    display: flex;
}

.form-check-box label, .form-check-box input{
    width: auto !important;
}

.form-padrao input, .form-padrao p{
    display: flex;
    width: 90%;
    margin: 3px 0px 18px 9px;
    border: 1px solid #dedede;
    border-radius: 5px;
    padding: 6px;
}

.form-padrao label, .form-check-box {
    display: flex;
    width: 90%;
    margin: 0 10px;
}

.form-padrao .error-message{
    color: #f00;
    margin-top: -20px;
}

.form-padrao div.error-message, div.submit-box {
    display: flex;
    width: 90%;
    margin: 0 10px 20px;
    justify-content: flex-end;
}

.select-box {
    width: 90%;
    margin: 0 10px 20px;
}

.form-padrao button{
    padding: 10px 15px;
    border: 0;
    color: #f7f7f7;
    background: #008031;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    margin-left: 12px;
}

.infor-color-danger {
    background-color: #dc3545 !important;
}

.form-padrao button:disabled {
    opacity: 0.7;
}

.form-padrao button:hover {
    color: #fff;
    opacity: 0.9;
}

.show.react-bs-table-sizePerPage-dropdown ul:last-child {
    display: block;
}

@media (max-width: 800px) {
    .form-padrao {
        max-width: 90%;
    }

    .form-padrao label, input, div.error-message, div.submit-box, .select-box {
        max-width: 100%;
    }

    .body-padrao-subtitle {
        max-width: 100%;
    }
}
