.table-shopping-cart {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin-top: 30px;
}

.table-shopping-cart td, .table-shopping-cart th {
    border-bottom: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

.table-shopping-cart tr > th {
  background: #dcdcdc;
}

.table-shopping-cart tr:hover{
  background: #dddddd;
}

.td-nome {
  width: 50%;
}

.td-normal{
  width: 10%;
}

.td-excluir{
  color: #aa131a;
  text-align: center;
  cursor: pointer;
}

.td-alterar{
  cursor: pointer;
  color: #06a0e9;
}

.sc-general-infor {
  padding: 20px;
  border: 1px solid #ddd;
  background: #fefefe;
}

.table-shopping-cart img {
  width: 50px;
  border-radius: 5px;
  height: 50px;
  object-fit: cover;
}

.sc-table-top{
  width: 100%;
}

.sc-table-top p{  
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  margin: 3px;
}

.sc-table-top td{
  vertical-align: top;
}

.sc-table-top td > tr{
  margin-bottom: 10px;
  display: flex;
  width: 100%;
}

$brand-primary: #ff0000;
$white: #fff;
$grey-light: #ededed;
%remain-steps {
  &:before {
    content: counter(stepNum);
    font-family: inherit;
    font-weight: 700;
  }
  &:after {
    background-color: $grey-light;
  }
}

.multi-steps {
  display: table;
  table-layout: fixed;
  width: 100%;
  > li {
    counter-increment: stepNum;
    text-align: center;
    display: table-cell;
    position: relative;
    color: $brand-primary;

    &:before {
      content: "\f00c";
      content: "\2713;";
      content: "\10003";
      content: "\10004";
      content: "\2713";
      display: block;
      margin: 0 auto 4px;
      background-color: $white;
      width: 36px;
      height: 36px;
      line-height: 32px;
      text-align: center;
      font-weight: bold;
      border: {
        width: 2px;
        style: solid;
        color: $brand-primary;
        radius: 50%;
      }
    }
    &:after {
      content: "";
      height: 2px;
      width: 100%;
      background-color: $brand-primary;
      position: absolute;
      top: 16px;
      left: 50%;
      z-index: -1;
    }
    &:last-child {
      &:after {
        display: none;
      }
    }

    &.is-active {
      @extend %remain-steps;
      &:before {
        background-color: $white;
        border-color: $brand-primary;
      }

      ~ li {
        color: #808080;
        @extend %remain-steps;
        &:before {
          background-color: $grey-light;
          border-color: $grey-light;
        }
      }
    }
  }
}

/*CSS para impressão*/
@media print {
  .multi-steps {
    display: none;
  }

  @page {
    margin: 0.5cm;
  }

  table{
    margin: 0;
  }

  #main-header .main-header-top{
    padding: 0;
    margin: 0;
  }

  .no-print{
    display: none;
  }
}